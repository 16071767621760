/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function () {
                // JavaScript to be fired on all pages
                $(".banner__trigger").on("click", function (e) {
                    e.preventDefault();
                    $(this).toggleClass("is-active");
                    $(".banner__nav").toggleClass("is-visible");
                });

                $(".more-container")
                    .on("click", ".js-more-open", function (e) {
                        e.preventDefault();
                        var $this = $(this);
                        var $parent = $this.closest(".more-container");

                        $this.hide();
                        $parent.find(".more").fadeIn();
                    })
                    .on("click", ".js-more-close", function (e) {
                        e.preventDefault();
                        var $parent = $(this).closest(".more-container");

                        $parent.find(".js-more-open").show();
                        $parent.find(".more").fadeOut();
                    });

                var $element = $(".float-label");

                $element.each(function (e) {
                    var $this = $(this);
                    var $input = $("input, textarea", $this);
                    var $label = $("label", $this);

                    $input
                        .bind("floatIt", function () {
                            if ($input.val() == "") {
                                $label.removeClass("is-visible");
                            } else {
                                $label.addClass("is-visible");
                            }
                        })
                        .on("keyup", function () {
                            $input.trigger("floatIt");
                        })
                        .on("focus", function () {
                            $label.addClass("is-focused");
                        })
                        .on("blur", function () {
                            $label.removeClass("is-focused");
                        })
                        .trigger("floatIt");
                });

                // Ouvre boutique dans un nouvel onglet
                $("#menu-item-677 a").attr("target", "_blank");
                console.log($("#menu-item-677 a"));
            },
            finalize: function () {
                var clinic = null;
                $("#plansClinic").on("change", function (e) {
                    e.preventDefault();
                    clinic = $(this).val();
                    if (
                        clinic == "Grand Littoral" ||
                        clinic == "Dromel" ||
                        clinic == "CHV Massilia" ||
                        clinic == "Aubagne"
                    ) {
                        clinic = clinic.toLowerCase().replace(/ /g, "_");
                    } else {
                        clinic = "null";
                    }
                    $(".map__pins").attr(
                        "class",
                        "map__pins map__pins--" + clinic
                    );
                });

                // Tu aimes les COOKIES ?
                var visited = $.cookie("visited");

                if (visited == null) {
                    $(".cookies").fadeIn();
                }
                // Prend donc un cookie, il expire dans 365 jours
                $.cookie("visited", "yes", { expires: 365, path: "/" });

                $(".cookies").on("click", function (e) {
                    e.preventDefault();
                    $(".cookies").fadeOut();
                });

                var $slider = $(".slider");

                if ($slider) {
                    var $slides = $(".slide", $slider);
                    var nbSlides = $slides.length;
                    var currSlide = 0;
                    var prevSlide = function () {
                        // Compute what should be the previous slide
                        var prev =
                            currSlide - 1 == -1 ? nbSlides - 1 : currSlide - 1;
                        $slides.eq(currSlide).removeClass("is-active");
                        currSlide = prev;
                        // Wait for 600ms that the fadeout animation is done before displaying the new slide
                        setTimeout(function () {
                            $slides.eq(prev).addClass("is-active");
                        }, 600);
                    };
                    var nextSlide = function () {
                        // Compute what should be the next slide
                        var next =
                            currSlide + 1 == nbSlides ? 0 : currSlide + 1;
                        $slides.eq(currSlide).removeClass("is-active");
                        currSlide = next;
                        // Wait for 600ms that the fadeout animation is done before displaying the new slide
                        setTimeout(function () {
                            $slides.eq(next).addClass("is-active");
                        }, 600);
                    };

                    // Append navigation arrows
                    $slider.append(
                        '<div class="slider__prev">Previous</div><div class="slider__next">Next</div>'
                    );

                    $slider.css("height", $slides.eq(0).height());

                    // Set the first slide as visible
                    $slides.eq(0).addClass("is-active");

                    // Change slide every 5 sec
                    var slideInterval = setInterval(function () {
                        nextSlide();
                    }, 8000);

                    // Handle arrows clicks
                    $(".slider__prev", $slider).on("click", function () {
                        clearInterval(slideInterval); // Stops the interval
                        prevSlide(); // Displays previous slide
                    });
                    $(".slider__next", $slider).on("click", function () {
                        clearInterval(slideInterval); // Stops the interval
                        nextSlide(); // Displays next slide
                    });
                }
            },
        },
        home: {
            init: function () {
                // JavaScript to be fired on the home page
                $(".hero__more").on("click", function (e) {
                    e.preventDefault();
                    var scrollHeight = $(window).height();
                    if ($(window).width() < 768) {
                        scrollHeight =
                            $(window).height() - $(".banner--mobile").height();
                    }
                    $(window).scrollTo(scrollHeight, 500, {
                        easing: "easeInOutQuart",
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $(window).scroll(function () {
                    $(".hero").css({
                        opacity: 1 - $(this).scrollTop() / 500,
                        filter: "blur(" + $(this).scrollTop() / 350 + "px)",
                    });
                });
            },
        },
        structures_veterinaires: {
            init: function () {
                // Gestion des heures d'ouverture
                var grandLittoralH = [8.3, 20];
                var massiliaH = [0, 24];
                var dromelH = [8, 20];
                var aubagneH = [8, 20];
                var currentD = new Date();
                var currentT =
                    currentD.getHours() + "." + currentD.getMinutes();
                var weekDays = [
                    "Dimanche",
                    "Lundi",
                    "Mardi",
                    "Mercredi",
                    "Jeudi",
                    "Vendredi",
                    "Samedi",
                ];
                var dayName = weekDays[currentD.getDay()];

                var isOpen = function (workingHours) {
                    if (
                        dayName != "Dimanche" &&
                        currentT > workingHours[0] &&
                        currentT < workingHours[1]
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                };

                // MASSILIA IS ALWAYS OPENED
                $(
                    '.structures__item[data-structure="massilia"] .structures__state span'
                )
                    .addClass("is-open")
                    .html("Ouvert");
                $(
                    '.structures__item[data-structure="massilia"] .structures__state i'
                ).attr("class", "anIcon anIcon--time-green");

                if (isOpen(grandLittoralH)) {
                    $(
                        '.structures__item[data-structure="grand_littoral"] .structures__state span'
                    )
                        .addClass("is-open")
                        .html("Ouvert");
                    $(
                        '.structures__item[data-structure="grand_littoral"] .structures__state i'
                    ).attr("class", "anIcon anIcon--time-green");
                }
                if (isOpen(dromelH)) {
                    $(
                        '.structures__item[data-structure="dromel"] .structures__state span'
                    )
                        .addClass("is-open")
                        .html("Ouvert");
                    $(
                        '.structures__item[data-structure="dromel"] .structures__state i'
                    ).attr("class", "anIcon anIcon--time-green");
                }
                if (isOpen(aubagneH)) {
                    $(
                        '.structures__item[data-structure="aubagne"] .structures__state span'
                    )
                        .addClass("is-open")
                        .html("Ouvert");
                    $(
                        '.structures__item[data-structure="aubagne"] .structures__state i'
                    ).attr("class", "anIcon anIcon--time-green");
                }
            },
            finalize: function () {
                // Coordonnées de Marseille
                var baseCoords = { lat: 43.32, lng: 5.359779999999992 };
                // Initialisation de la map
                var map = new google.maps.Map(document.getElementById("map"), {
                    center: baseCoords,
                    scrollwheel: false,
                    zoom: 11,
                });
                // Placement des marqueurs
                var iconBase =
                    "http://www.animedis.fr/wp-content/themes/corporate/assets/images/marker.png";
                var iconActive =
                    "http://www.animedis.fr/wp-content/themes/corporate/assets/images/marker_active.png";
                var i = 0;

                // Marqueurs à la main pour pouvoir les activer au click
                var markerGrandLittoral = new google.maps.Marker({
                    map: map,
                    icon: iconBase,
                    position: { lat: 43.3611397, lng: 5.353613399999972 },
                });
                var markerMassilia = new google.maps.Marker({
                    map: map,
                    icon: iconBase,
                    position: { lat: 43.307272, lng: 5.422997699999996 },
                });
                var markerDromel = new google.maps.Marker({
                    map: map,
                    icon: iconBase,
                    position: {
                        lat: 43.26934600000001,
                        lng: 5.404905699999972,
                    },
                });
                var markerAubagne = new google.maps.Marker({
                    map: map,
                    icon: iconBase,
                    position: { lat: 43.284424, lng: 5.581963 },
                });

                // Désactive tous les marqueurs (iconBase)
                var disableMarkers = function () {
                    markerGrandLittoral.setIcon(iconBase);
                    markerMassilia.setIcon(iconBase);
                    markerDromel.setIcon(iconBase);
                    markerAubagne.setIcon(iconBase);
                };

                // Accordéon des structures
                $(".js-structure-toggle").on("click", function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var $target = $($this.attr("data-href"));
                    var structure = $this.attr("data-structure");
                    // Montrer le détail correspondant
                    $(".structures__detail__item")
                        .not($target)
                        .removeClass("is-visible");
                    $target.addClass("is-visible");
                    // Activer le bon onglet
                    $(".structures__item").not($this).removeClass("is-active");
                    $this.addClass("is-active");
                    $(".structures__detail__close").addClass("is-visible");

                    disableMarkers();

                    if (structure == "grand_littoral") {
                        markerGrandLittoral.setIcon(iconActive);
                    } else if (structure == "massilia") {
                        markerMassilia.setIcon(iconActive);
                    } else if (structure == "dromel") {
                        markerDromel.setIcon(iconActive);
                    } else if (structure == "aubagne") {
                        markerAubagne.setIcon(iconActive);
                    }
                });

                markerGrandLittoral.addListener("click", function () {
                    $(
                        '.structures__item[data-structure="grand_littoral"]'
                    ).trigger("click");
                });
                markerMassilia.addListener("click", function () {
                    $('.structures__item[data-structure="massilia"]').trigger(
                        "click"
                    );
                });
                markerDromel.addListener("click", function () {
                    $('.structures__item[data-structure="dromel"]').trigger(
                        "click"
                    );
                });
                markerAubagne.addListener("click", function () {
                    $('.structures__item[data-structure="aubagne"]').trigger(
                        "click"
                    );
                });

                $(".js-detail-close").on("click", function (e) {
                    e.preventDefault();
                    $(".structures__detail__close").removeClass("is-visible");
                    $(".structures__detail__item.is-visible").removeClass(
                        "is-visible"
                    );
                    $(".structures__item.is-active").removeClass("is-active");
                    disableMarkers();
                });
            },
        },
        candidature: {
            finalize: function () {
                var $_GET = {};
                document.location.search.replace(
                    /\??(?:([^=]+)=([^&]*)&?)/g,
                    function () {
                        function decode(s) {
                            return decodeURIComponent(s.split("+").join(" "));
                        }

                        $_GET[decode(arguments[1])] = decode(arguments[2]);
                    }
                );

                if ($_GET["type"] == "spontane") {
                    var type = "Candidature spontanée";
                } else if ($_GET["type"] == "assistance") {
                    var type = "Auxiliaire spécialisé(e) vétérinaire";
                } else if ($_GET["type"] == "veterinaire") {
                    var type = "Vétérinaire";
                }

                $('#job option[value*="' + type + '"]').prop("selected", true);
            },
        },
        page_template_template_structure: {
            init: function () {
                var structures = {
                    massilia:
                        "https://www.google.com/maps/embed?pb=!1m0!3m2!1sfr!2sfr!4v1483972199712!6m8!1m7!1sd_v8eQP1jeQAAAQn7ygePQ!2m2!1d43.30719986580777!2d5.423118766870175!3f288!4f0!5f0.7820865974627469",
                    dromel: "https://www.google.com/maps/embed?pb=!1m0!3m2!1sfr!2sfr!4v1483972425309!6m8!1m7!1sW0Gl6a3DQCHgaQX7A2vyQA!2m2!1d43.26914201450316!2d5.40453898948391!3f129.3269449660489!4f-5.4704738472414505!5f0.7820865974627469",
                    grand_littoral:
                        "https://www.google.com/maps/embed?pb=!1m0!3m2!1sfr!2sfr!4v1487093219093!6m8!1m7!1sDPXaPwQrllcAAAQvOleCsQ!2m2!1d43.36444072740304!2d5.351331613437765!3f73!4f0!5f0.7820865974627469",
                    la_ciotat:
                        "https://www.google.com/maps/embed?pb=!4v1692871260514!6m8!1m7!1sCAoSLEFGMVFpcE9ZZmFnc1Vua1lfTG1MOVd1MjRjX2NCSGNXVUZoX0RnQ3NtOWdl!2m2!1d43.185539393512!2d5.6083026442722!3f23.3!4f-9.219999999999999!5f0.7820865974627469",
                    aubagne:
                        "https://www.google.com/maps/embed?pb=!4v1692871339923!6m8!1m7!1sCAoSLEFGMVFpcE5PTnFhUm9PTm9vSlVVV3JtNGxVTVNXMVB4MVg2M1h6cklkcWVW!2m2!1d43.284515627341!2d5.5819812517656!3f92.09!4f-15.900000000000006!5f0.7820865974627469",
                };

                var $visit = $(".js-visit");

                $visit.on("click", function (e) {
                    e.preventDefault();
                    var href = $visit.attr("href");
                    var structure = structures[href];

                    if (structure) {
                        var frameWidth = $(window).width() - 48;
                        var frameHeight = $(window).height() - 48;
                        var template =
                            '<iframe class="iframe" border="0" width="' +
                            frameWidth +
                            '" height="' +
                            frameHeight +
                            '" src="' +
                            structure +
                            '"></iframe><span class="iframe__close"><i class="anIcon anIcon--close"></span>';

                        if ($(".iframe").length == 0) {
                            $("body").append(template);
                        } else {
                            $(".iframe").fadeIn("fast");
                            $(".iframe__close").fadeIn("fast");
                        }

                        $(document).on("click", ".iframe__close", function (e) {
                            $(".iframe").fadeOut("fast");
                            $(".iframe__close").fadeOut("fast");
                        });
                    }
                });

                $(".structure__category").on("click", function (e) {
                    var $this = $(this);

                    if ($(window).width() < 1024 && !$(e.target).is("a")) {
                        e.preventDefault();
                        $(".structure__category")
                            .not($this)
                            .removeClass("is-active");
                        $this.toggleClass("is-active");
                    }
                });

                $(document).on("click", function (e) {
                    var $target = $(e.target);
                    if (
                        !$target.hasClass("structure__category") &&
                        $target.closest(".structure__category").length == 0
                    ) {
                        $(".structure__category.is-active").removeClass(
                            "is-active"
                        );
                    }
                });
            },
        },
        blog: {
            finalize: function () {
                sizePosts();

                $(window).resize(function () {
                    sizePosts();
                });
            },
        },
        archive: {
            finalize: function () {
                sizePosts();

                $(window).resize(function () {
                    sizePosts();
                });
            },
        },
    };

    function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }

    function sizePosts() {
        if ($(window).width() > 768) {
            var postsHeaderHeights = [];
            var postsSummaryHeights = [];
            // Store the headers heights
            $(".post__header").each(function () {
                postsHeaderHeights.push($(this).outerHeight());
            });
            // Store the summaries heights
            $(".post__summary").each(function () {
                postsSummaryHeights.push($(this).outerHeight());
            });
            // THEN apply the max value to each headers
            $(".post__header").each(function () {
                $(this).css("height", getMaxOfArray(postsHeaderHeights) + "px");
            });
            // and to each summaries
            $(".post__summary").each(function () {
                $(this).css(
                    "height",
                    getMaxOfArray(postsSummaryHeights) + "px"
                );
            });
        } else {
            // Reset heights (useful in case of a resize)
            $(".post__header, .post__summary").each(function () {
                $(this).css("height", "inherit");
            });
        }
    }

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
